@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&display=swap");

* {
  margin: 0;
  padding: initial;
}

body {
  font-family: "'Mulish'", sans-serif;
  background-color: #f0f0f0;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aspect-ratio {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.content {
  text-align: left;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

li {
  list-style-type: none;
}

.nav {
  position: absolute;
  background-color: #ffffff;
  opacity: 0.6;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 7px 20px;
  z-index: 10;
}

.nav.expanded {
  height: 100%;
}

ul.icons {
  padding: 0%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: normal;
  cursor: pointer;
}

li.hamburger {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

li.empty {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  width: 52px;
}

li.notification {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

li.notification img {
  width: 60px;
  display: block;
  margin: 0 auto;
}

li.volume {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  margin-right: 20px;
}

li.users {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.carousel-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.full-height {
  height: 100vh;
}

.tray {
  position: absolute;
  top: 32px;
  left: 0;
  width: calc(100% - 40px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 5;
  padding: 20px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, background-color 0.5s ease-in-out;
  transform: translateY(-100%);
  opacity: 0;
  height: 100%;
  overflow-y: scroll;
}

.tray.initial {
  background-color: rgba(255, 255, 255, 1);
}

.tray.visible {
  transform: translateY(0);
  opacity: 1;
}

.menu-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 20px;
  text-align: left;
  width: 100%;
  height: 100vh;
}

.menu-container a {
  text-decoration: none;
  color: #000000;
}

.menu-column {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.menu-column.initial {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.menu-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.menu-column.left {
  grid-column: 1 / 2;
}

.menu-column.right {
  grid-column: 2 / 3;
}

.menu-column ul {
  list-style: none;
  padding: 0;
}

.menu-column li {
  margin: 10px 0;
}

.logo {
  grid-column: 1 / 3;
  grid-row: 2 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  max-width: 30%;
  height: auto;
}

li.stronger {
  font-weight: 600;
}

li.pushed {
  margin-left: 20px;
}

.video-wrapper {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.video-wrapper.visible {
  opacity: 1;
}

.video-js {
  background-color: #ffffff !important;
}

.column-header h3 {
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.column-header p {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
}

.over-column {
  padding-bottom: 60px;
}

.over-column a,
.over-column a:visited {
  color: #000000;
  text-decoration: none;
}

.card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  position: relative;
}

.card-header small {
  display: block;
  font-size: 12px;
  color: rgba(105, 105, 105, 1);
  margin-bottom: 6px;
}

.card .article-title a {
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.img-fluid {
  width: 100%;
  margin: 6px 0;
}

ul.bullets {
  padding-left: 16px;
}

ul.bullets li {
  font-size: 14px;
  list-style-type: disc;
}

.read-more {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #fe001a;
  color: #ffffff;
  padding: 4px 8px;
  margin: 10px auto 0;
  display: inline-block;
  border-radius: 3px;
}

.card.inline {
  display: inline-block;
  margin-right: 10px;
}

.notif {
  text-align: center;
  padding: 20% 10% 0 10%;
}

.notif a,
.notif a:visited {
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 36px;
  color: #344767;
  text-decoration: none;
}

.notif h3 {
  font-family: "Mulish", sans-serif;
  font-weight: 300;
  font-size: 32px;
  color: #fe001a;
  margin-bottom: 20px;
}

@media screen and (min-width: 800px) {
  body.landscape .menu-container {
    padding: 3%;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1400px) {
  body.landscape .menu-container {
    padding: 10%;
    box-sizing: border-box;
  }
}

body.landscape .menu-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body.landscape .menu-container ul li {
  margin-bottom: 10px;
}

body.landscape .menu-container .logo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

body.landscape .menu-container .logo img {
  max-width: 20%;
}

body.landscape .menu-container .menu-column.left,
body.landscape .menu-container .menu-column.right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

body.landscape .menu-container .logo {

}

body.landscape div.article-title a img {
  width: 200px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 5px;
}

body.landscape .card.opened {
  padding-left: 240px;
}

.login-wrapper {
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.95);
  z-index: 1;
  transition: opacity 0.5s;
}

.login-wrapper.show {
  opacity: 1;
}

.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: block;
  margin: 0 auto;
  padding: 50px;
  width: 250px;
  background-color: rgba(255,255,255,.9);
  border-radius: 10px;
  /*border: .2px solid #fe001a;*/
}

.login-title {
  padding-bottom: 15px;
  font-size: 24px;
  color: #555;
  text-transform: uppercase;
}

.error {
  color: #fe001a;
}

.form-row {
  padding: 5px 0;
}

.form-field {
  width: 100%;
  padding: 9px 13px;
  font-size: 18px;
  color: #666;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  box-sizing: border-box;
}

.form-submit {
  margin-top: 10px;
  padding: 8px 15px;
  font-size: 18px;
  /*border: 1px solid #ccc;*/
  border: 0;
  border-radius: 5px;
  color: white;
  background-color: #fe001a;
  cursor: pointer;
  transition: background-color .3s;
}

.form-submit:hover {
  background: darkred;
}

.login-message {
  position: absolute;
  left: 50%;
  top: 90%;
  z-index: 10;
  transform: translateX(-50%);
  text-align: center;
}